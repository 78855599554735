import './EntradaEstoque.css';
import Navbar from '../navbar/navbar';
import { useState } from 'react';
import BuscaVazaia from '../components/buscavazia/buscaVazia';
import api from '../../services/api';
import { vhostV1} from '../funcoes'
import Footer from '../components/footer/Footer';
import { useEffect } from 'react';
import { Link,Navigate } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import ModalProduto from '../components/modalProduto/ModalProduto';
import ModalAdicaoEstoque from './ModalAdicao';




function NovaEntrada(){       
  
  
    const inputs = document.querySelectorAll("input, select");
    // Adicione um manipulador de eventos para o evento "keydown" em cada entrada ou select
    inputs.forEach(input => {
      input.addEventListener("keydown", e => {
        // Se a tecla pressionada for Enter
        if (e.key === "Enter") {
          e.preventDefault(); // evita que a tecla "Enter" envie um formulário, se aplicável

          // Obtenha o índice do elemento atual
          const currentIndex = Array.from(inputs).indexOf(e.target);
  
          // Mova o foco para o próximo elemento de entrada ou select
          const nextIndex = currentIndex + 1;
          if (nextIndex < inputs.length) {
            inputs[nextIndex].focus();
          }
        }
      });
    });
   
   let config = {
      headers: {
        'Authorization': localStorage.getItem("token")
      }
    }
    var data = new Date();
    var dia = String(data.getDate()).padStart(2, '0');
    var mes = String(data.getMonth() + 1).padStart(2, '0');
    var ano = data.getFullYear();
    const dataAtual = dia + '/' + mes + '/' + ano;
    

   
   const [razao,setRazao] = useState();
   const [buscaPessoa,setBuscaPessoa] = useState();
   const [resultPessoa,setResultPessoa] = useState();
   const [pessoas,setPessoas] = useState();
   const [responsavel,setResponsavel] = useState();
   const [sResult,setSresult] = useState(0);
   const [descricao,setDescricao] = useState('')
   const [venda,setVenda] = useState(0);
   const [vendaDefault,setVendaDefault] = useState(0);
   const [qtd,setQtd] = useState(0);
   const [desconto,setDesconto] = useState(0);
   const [descontoDefault,setDescontoDefault] = useState(0);
   const [total,setTotal] = useState(0);
   const [Itens,setItens] = useState([]);
   const [idVenda,setIdVenda] = useState(0);
   const [totalPedido,setTotalPedido] = useState(0);
   const [sucesso,setSucesso]      = useState('N')
   const [mensagem, setMensagem]   = useState('');
   const [dataEmissao,setDataEmissao] = useState(new Date)
   const [estoqueAtual,setEstoqueAtual] = useState(0);
   const [pesquisaProduto,setPesquisaProduto] = useState('');
   


   const [codProduto,setCodProduto] = useState('');

   function calculaPreco(){

      setTotal(qtd * vendaDefault - desconto);
   }


    function fecharEntrada(){
      api.post(vhostV1('webcash/produto/confirmarentradaestoque'), {
        pedido: idVenda,
        tipo: 1        
      },config)
      .then(function(response){
        setMensagem('');
        setSucesso('S');
        console.log()
    }).catch((erro) =>{
        setMensagem(erro);
        setSucesso('N');
    });

    }


   async function retornaVenda(){
      await api.get(vhostV1('webcash/produto/retornaentrada/'+idVenda),config)
       .then(function (response) {
         setItens(response.data.dados.ITENS);
         setTotalPedido(response.data.dados.VALOR);
                  
       })
       .catch(function (error) {
        
         console.log(error);

                 
       });
    }



   
    
   function buscaItem(){
      console.log('aqui')
      api.post(vhostV1('webcash/produto/retornaproduto/'+codProduto), {
         loja: localStorage.getItem('loja'),
         empresa: 0         
       },config)
       .then(function (response) {
         setSresult(response.data.result);
         setDescricao(response.data.dados.DESCRICAO);
         setVendaDefault(response.data.dados.VENDA);
         setVenda(response.data.dados.VENDA);  
         setEstoqueAtual(response.data.dados.ESTOQUE)               
        })
       .catch(function (error) {
        
         console.log(error);
                 
       });
   }

      // criando um pedido
      useEffect(function(){
         api.post(vhostV1('webcash/produto/entradaestoque'), {
          EMPRESA:0,
          COD_COLABORADOR:localStorage.getItem('colaborador')
           },config)
           .then(function (response) {
              setIdVenda(response.data.result); 
              
             }                               
           )
           .catch(function (error) {
             console.log(error);
                     
           }); 
   
           
       },[])

       

      const [isAdicaoOpen,setIsAdicaoOpen] = useState(false);

       function openModalAdicao(){
          setIsAdicaoOpen(true)
       }

      async function closeModalAdicao(){
        retornaVenda();   
        setIsAdicaoOpen(false)        
       }

   



   return <div className="conteiner-fluid titulo ">
   <Navbar/>
   
   <ModalAdicaoEstoque isOpen={isAdicaoOpen}
                 onRequestClose={closeModalAdicao}
                 idVenda={idVenda}
                 codProduto={codProduto}
                 setIsAdicaoOpen={setIsAdicaoOpen}
                 titulo="Adicionar Item "
                 />      
   
    
   <div className="offset-2 col-8">
         <h4>Entrada de estoque</h4> 

         <hr />
          <div className="conteiner-fluid">
          <form className="row tela">
             
             <div className="col-2">
                 <label htmlFor="inputAddress" className="form-label">N ENTRADA</label>
                 <input type="text" value={idVenda} className="form-control" id="inputCodigo" tabIndex="1" required autoComplete="off"/>
             </div>

             <div className="col-4">
                 <label htmlFor="inputAddress" className="form-label">COLABORADOR</label>
                 <input type="text" value={localStorage.getItem("usuario")} className="form-control" id="inputCodigo" tabIndex="1"  disabled/>
             </div>

             <div className="col-3">
                 <label htmlFor="inputAddress" className="form-label">DATA</label>
                 <input type="text"  value={dataAtual} className="form-control" disabled/>
             </div>

             <div className="col-3">
                 <label htmlFor="inputAddress" className="form-label">TOTAL</label>
                  <NumericFormat className="form-control"
                                      id="inputValorTotal" 
                                      value={totalPedido.toLocaleString("pt-br", {style:"currency", currency:"BRL"})}
                                      tabIndex="9"
                                      allowLeadingZeros
                                      decimalSeparator=','
                                      thousandSeparator='.'
                                      decimalScale={2}
                                      prefix="R$ "
                                      
                                 />  
             </div>



             <hr />
             <h4>Itens Movimentados</h4>
             <hr />
             <div className="col-4">
             <button className="btn btn-success btn-cli" onClick={openModalAdicao} type="button" data-bs-toggle="modal" data-bs-target="#adicaoItem"><i className="bi bi-upc"></i>Adicionar</button>
             </div>

                        
           </form>

            

           <table className="table table-hover table-striped">
            <thead>
            <tr>
                <th scope="col">Codigo</th>
                <th scope="col">Descricao</th>
                <th scope="col">Qtd</th>
                <th scope="col">Compra</th>
                <th scope="col">Total</th>
                
                </tr>
            </thead>
            <tbody className="table-group-divider">
              {Itens.length > 0?  
                 Itens.map((item,i)=> {
                  return <tr key={item.i}>
                      <td>{item.COD_PRODUTO}</td>          
                      <td>{item.DESCRICAO}</td>
                      <td>{Number(item.QTD).toFixed(3)}</td>
                      <td>{new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(item.COMPRA)}</td>
                      <td>{new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(item.TOTAL)}</td>
                
                  </tr>
              } ):null}  
            </tbody>
          </table>
           
         </div>    
         
         <Link to="/app/estoque/entrada" type="submit" className="btn btn-outline-primary butao"  tabIndex="14">Desistir</Link>
         <button type="submit" onClick={fecharEntrada} className="btn btn-primary butao" tabIndex="13">Salvar</button>
        
         {mensagem.length > 0 ? <div className="alert alert-danger mt-2" role="alert">{mensagem}</div> : null}
         {sucesso === 'S' ? <Navigate to='/app/estoque/entrada' /> : null}

        
      </div> 

      
   </div>
   


}


export default NovaEntrada;