import React,{useState} from "react";
import Navbar from "../navbar/navbar";
import { api } from "../../services/api";
import { Link,Navigate,useParams } from 'react-router-dom'
import { useEffect } from "react";
import './grupo.css'
import { vhostV1 } from "../funcoes";
import SweetAlert from "react-bootstrap-sweetalert";





function NovoGrupo(props){

  document.addEventListener("keydown", function(e) {

    if(e.keyCode === 13) {
      
      e.preventDefault();
      
    }
  
  });

  
  const {id} = useParams();

   const [descricao,setDescricao]  = useState('');
   const [cor,setCor]              = useState('');
   const [carga,setCarga]          = useState(0);
   const [sucesso,setSucesso]      = useState('N')
   const [mensagem, setMensagem]   = useState('');
   const [erroValidacao, setErrovalidacao] = useState(false);
   const [msgErro, setmsgErro] = useState('');
   
    
   let config = {
    headers: {
      'Authorization': localStorage.getItem("token")
    }
  }



   //buscar grupo se for edicao
  useEffect(function(){
    
    api.post(vhostV1('webcash/grupo/retorna'), {
        loja: localStorage.getItem('loja'),
        empresa: 0,
        cod_grupo: id
      },config)
      .then(function (response) {
        setDescricao(response.data.dados.DESCRICAO); 
        setCarga(response.data.dados.CARGA); 
        setCor(response.data.dados.COR);    
        
                 
      })
      .catch(function (error) {
       
        console.log(error);

                
      });

      
  },[])



  
   function salvaGrupo(e){
    e.preventDefault();

    if(descricao.length === 0){  
      {setmsgErro("DESCRIÇÃO DO GRUPO NÃO PODE ESTAR VAZIO");
          setErrovalidacao(true)}
      return    
  }else if(descricao === ' '){
    {setmsgErro("DESCRIÇÃO DO GRUPO NÃO PODE ESTAR VAZIO");
      setErrovalidacao(true)}
  }
    
    else{

        api.post(vhostV1('webcash/grupo/incluir'),{
            loja:11,
            COD_GRUPO:id,
            EMPRESA:0,
            DESCRICAO:descricao,
            COR:cor,
            CARGA:carga,
          },config)
        .then(function(response){
            setMensagem('');
            setSucesso('S');
            console.log()
        }).catch((erro) =>{
            setMensagem(erro);
            setSucesso('N');
        });
     } }


     return <div className="conteiner-fluid titulo ">
         <Navbar/>
         <div className="offset-lg-1 col-lg-10">
               <h2>Cadastro de Grupo</h2>      
               <hr />
                <div className="conteiner-fluid">
                <form className="row tela" onsubmit="return false;">
                   
                   <div className="col-7">
                       <label htmlFor="inputAddress" className="form-label">DESCRICAO</label>
                       <input type="text"  value={descricao}  onChange={(e)=> setDescricao(e.target.value)} className="form-control" id="inputDescricao" tabIndex="1" required autoComplete="off" maxLength='20'/>
                   </div>
                   {/* <div className="col-2">
                       <label htmlFor="inputAddress" className="form-label">COR</label>
                       <input type="color" value={cor} onChange={(e)=> setCor(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                   </div>                    */}
                   <div className="col-md-2">
                       <label htmlFor="inputState" className="form-label">MOBILE</label>
                       <select id="inputUnidade" value={carga} onChange={(e)=> setCarga(e.target.value)} className="form-select" tabIndex="3" >
                           <option VALUE={1}>SIM</option>
                           <option VALUE={0}>NÃO</option>
                           
                       </select>
                   </div>
                   
                   
                   <div className="col-12">
                   <hr />
                       <Link to="/app/grupo" type="submit" className="btn btn-outline-primary butao"  tabIndex="14">Desistir</Link>
                       <button type="submit" onClick={salvaGrupo} className="btn btn-primary butao" tabIndex="13">Salvar</button>
                   </div>
       
                   {mensagem.length > 0 ? <div className="alert alert-danger mt-2" role="alert">{mensagem}</div> : null}
                   {sucesso === 'S' ? <Navigate to='/app/grupo' /> : null}
       
                   </form> 
            </div>

            {
           erroValidacao ? 
           <SweetAlert
              danger
              confirmBtnText="OK"
              confirmBtnBsStyle="danger"
              title="Erro"
              onConfirm={() => setErrovalidacao(false)}                            
            >
              {msgErro}
            </SweetAlert>:null
           
          }
        </div>  
   </div>

}



export default NovoGrupo;