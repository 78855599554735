import React, { useState, useEffect } from 'react';
import { api } from '../../services/api';
import { Link } from 'react-router-dom';
import './pedidos.css';
import Navbar from '../navbar/navbar';
import logoNfce from '../../images/nfce.png'
import logoRecibo from '../../images/recibo.png'
import { vhostV1 } from '../funcoes';
import BuscaVazaia from '../components/buscavazia/buscaVazia';
import PedidosPdf from '../reports/pedidos/sinteticos';
import VendasPorFinalizadora from '../home/VendasPorFinalizadora';
import VendasPorCupom from '../reports/vendas/vendasporcupom';
import Loading from '../components/loading/Loading';
import SweetAlert from 'react-bootstrap-sweetalert';
import CupomFiscal from '../reports/nfe/CupomFiscal';
import cupom from '../reports/nfe/venda';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import Widget from '../components/widget/widget';
import customizeScrollbar from "customsb";
import SRI_pagination from '../components/pagination/pagination';
import VendasProdutoPDF from '../reports/pedidos/relProdutos';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;




function ListaPedidos() {  


     const [pedidos,setPedidos] = useState([]); 
     const [sResult,setSresult] = useState(0);
     const [tpRelatorio,setTipoRelatorio] = useState(0);
     const [tipo,setTipo] = useState('TODOS');
     const [caixa,setCaixa] = useState('TODOS');  
     const [dataInical,setDataInicial] = useState(new Date()); 
     const [dataFinal,setDataFinal] = useState(new Date());
     const [load,setLoad] = useState(false);
     const [confirmaEmissao,setConfirmaEmissao] = useState(false);
     const [erroEmissao,setErroEmissao] = useState(false);
     const [base64Data, setBase64Data] = useState('');
     const [numPages, setNumPages] = useState(null);
     const [msgErro,setmsgErro] = useState('');
     const [totalPaginas,setTotalPaginas] = useState(0); 
     const [nPag,setnPag] = useState(1);
     const [caixasDisponiveis, setCaixasDisponiveis] = useState([]);

     const onDocumentLoadSuccess = ({ numPages }) => {
       setNumPages(numPages);
     };

     function handlePageChange(value){
      if(value === "&laquo;" || value === '... '){
        setnPag(1);
      }else if (value === "&lsaquo;" ){
        if (nPag !== 1){
          setnPag(-1);
        }
      }else if(value === "&rsaquo;"){
        setnPag(+1);        
      }else if(value === "&raquo;" || value === ' ...' ){
        setnPag(totalPaginas);        
      }else{
        setnPag(value);
      }    
     
     }
       

    let config = {
      headers: {
         'Authorization': localStorage.getItem("token")
         }
      }


      async function fetchBase64Data(schave){
        try {
          setLoad(true)
            const response = await api.post(vhostV1('webcash/nfe/danfepdf'),{
            loja: localStorage.getItem('loja'),
            empresa: 0,
            chave:schave
          },config);
          setLoad(false)
          console.log(response)
          setBase64Data(response.data.dados.data.base64);
          convertAndOpenPDF(response.data.dados.data.base64);
        } catch (error) {
          console.error('Erro ao buscar base64 da API:', error);
        }
      };

      const convertAndOpenPDF = (base64String) => {
        const byteCharacters = atob(base64String);
        const byteArrays = [];
    
        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
          const slice = byteCharacters.slice(offset, offset + 512);
    
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
    
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
    
        const pdfBlob = new Blob(byteArrays, { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(pdfBlob);
    
        window.open(pdfUrl, '_blank');
      };

           // Aplicando a personalização com opções diferentes
// Abaixo é somente um exemplo de personalização, adapte para o seu projeto
useEffect(() => {
  // O uso do useEffect garante que a função seja chamada apenas no lado do cliente
  customizeScrollbar({
    scrollbarWidth: "10px",
    trackBackground: "#333",
    thumbBackground:
      "linear-gradient(178.8deg, #ffa066 47.41%, #ff9666 65.18%, #ff783a 90.84%)",
    thumbBorderRadius: "10px",
  });
}, []);

useEffect(function(){
    
  api.get(vhostV1('webcash/configcaixa'),config)
    .then(function (response) {
      console.log(response);
      
      const caixas = response.data.dados;
      setCaixasDisponiveis(caixas);
                                  
               
    })
    .catch(function (error) {
     
      console.log(error);

              
    });

    
  },[])


 
  

      const getDadosRelatorio = async()=>{
        
        let response = {};
        try {
          response = await api.post(vhostV1('webcash/vendas/relsintetico'),{
            loja: localStorage.getItem('loja'),
            empresa: 0,
           //dtinicial:"2023-02-13T13:45:00.000Z",
            dtinicial:dataInical,
            dtfinal:dataFinal,
            tipo:tipo,
            caixa:caixa
          }, config)
          ;
          
          
          
        } catch (error) {
          console.log(error);          
        }
        console.log(response.data.dados); 
        return response.data.dados;
      
        //setItens(response.data.dados); 
        //setResultItens(response.data.result)     
      
      }


      const getDadosRelatorioFinalizadora = async()=>{
        
        let response = {};
        try {
          response = await api.post(vhostV1('webcash/vendas/listavendas/finalizadora'),{
            loja: localStorage.getItem('loja'),
            empresa: 0,
           //dtinicial:"2023-02-13T13:45:00.000Z",
            dtinicial:dataInical,
            dtfinal:dataFinal,
            tipo:tipo,
            caixa:caixa
          }, config)
          
        } catch (error) {
          console.log(error);          
        }
         console.log(response);
         
        return response.data.dados;
      
        //setItens(response.data.dados); 
        //setResultItens(response.data.result)             
      
      }

      const getDadosRelatorioProduto = async()=>{
        let response = {};
        try {
          response = await api.post(vhostV1('webcash/dashboard/produtoMaisVendido'),{
            loja: localStorage.getItem('loja'),
            empresa: 0,
            data: dataInical,
            dataFinal: dataFinal,
        }, config)}
        
        catch (error) {
          console.log(error);          
        }
         console.log(response);
         
         
        return response.data.dados; }
      

      const getDadosRelatorioCupom = async()=>{
        
        let response = {};
        try {
          response = await api.post(vhostV1('webcash/vendas/relitens'),{
            loja: localStorage.getItem('loja'),
            empresa: 0,
           //dtinicial:"2023-02-13T13:45:00.000Z",
            dtinicial:dataInical,
            dtfinal:dataFinal,
            tipo:tipo,
            caixa:caixa
          }, config)
          
        } catch (error) {
          console.log(error);          
        }
         console.log(response);
         
         
        return response.data.dados;
      
        //setItens(response.data.dados); 
        //setResultItens(response.data.result)
             
      
      }

      const selectRelatorio =[
        {
          value: 0,
          descricao: "lista"
        },
        {value: 1,
          descricao: "Agrupado por Cupom"
        },
        {
          value: 2,
          descricao: "Produtos vendidos"
        },
        {
          value: 3,
          descricao: "Venda por finalizadora"
        }
      ]




      async function gerarRelatorio () { 

        if(Number(tpRelatorio) === 0 ){
        const tItens = await getDadosRelatorio();
        console.log(tItens);
         
        
          PedidosPdf(tItens);
        }else if(Number(tpRelatorio) === 1){
          
          const tItens = await getDadosRelatorioCupom();
          console.log(tItens);
          
          VendasPorCupom(tItens);
        }else if(Number(tpRelatorio) === 2){
          const tItens = await getDadosRelatorioProduto();
          console.log(tItens);


          VendasProdutoPDF(tItens);
        }
        else{
          const tItens = await getDadosRelatorioFinalizadora();
          VendasPorFinalizadora(tItens);
          console.log(tItens);
          
        }      
               
      }  

      
      


    function filtrarPedidos(){
      setLoad(true)
      //const dt = new Date(dataInical);

      //const iso = dt.toISOString();
      //console.log(iso); // 👉️ "2022-07-21T00:00:00.000Z"


      api.post(vhostV1('webcash/vendas/listar'), {
        loja: localStorage.getItem('loja'),
        empresa: 0,
       //dtinicial:"2023-02-13T13:45:00.000Z",
        dtinicial:dataInical,
        dtfinal:dataFinal,
        tipo:tipo,
        caixa:caixa
      },config)
      .then(function (response) {   
        console.log(response.data.dados);
        
        setPedidos(response.data.dados); 
        setSresult(response.data.result); 
        setLoad(false);
    
      })
      .catch(function (error) {
        console.log(error);
        setLoad(false);
                
      });
      
       

    }  

  

    function imprimirNota(){

      CupomFiscal(cupom);

    }


    async function EmitirNFCe (e,tipo) { 
      setLoad(true)
      
      const tItens = await enviarNFce(e,tipo);    
      //console.log('retorno '+tItens);
      
      filtrarPedidos();
      
        
    }

    const enviarNFce = async(npedido,tipo)=>{
      setLoad(true)
      const sJson = {}
        sJson.loja=localStorage.getItem('loja'); 
        sJson.empresa=0;
        sJson.dav=npedido;
        sJson.tipo=tipo;
              
         const res = await api.post(vhostV1('webcash/nfe/emitir/nfe'), sJson, config)  
        
        if (res.status===200) {      
         filtrarPedidos(); 
         setConfirmaEmissao(true)       
        }else {
          setLoad(false)
          //filtrarPedidos();
          setmsgErro(res.response.data.erro);
          setErroEmissao(true)
        }      
     
    }



   //buscar pedidos
    useEffect(function(){
      api.post(vhostV1('webcash/vendas/listar'), {
        loja: localStorage.getItem('loja'),
        empresa: 0,
      // dtinicial:"2023-02-13T13:45:00.000Z",
        dtinicial:dataInical, 
        dtfinal:dataFinal,
        tipo:tipo,
        caixa:caixa
      },config)
      .then(function (response) {        
        console.log(response.data.dados);
        
        setPedidos(response.data.dados); 
        setSresult(response.data.result); 
    
      })
      .catch(function (error) {
        console.log(error);
                
      });
      
      
    },[])

    async function cencelaNFE(nfeChave) {

      
     
      const res = await api.post(vhostV1('webcash/nfe/cancelaNfe'), {
        loja: localStorage.getItem('loja'),
        empresa: 0,
        chave: nfeChave
      },config);
      if (res.response.status===200) {
        try {
          alert(`NFe cancelada com sucesso!`);
        } catch (err){

        }         
      } else {
        setmsgErro(res.response.data.erro);
        setErroEmissao(true)
      }
      

  }
   
      

    return <div>

        <Navbar/>
        <div className="container-fluid titulo offset-lg-1 col-lg-10">
        
       
        <div className="d-flex justify-content-between">
              <h2 className="d-inline">Vendas</h2>
              <div>
                <Link type="button" className="btn btn-primary btn-cli"  to="/app/pedido/novopedido"><i className="bi bi-plus-circle-fill"></i> Pedido</Link>
                <button type="button" className="btn btn-secondary btn-cli" data-bs-toggle="modal" data-bs-target="#moda-filtro"><i className="bi bi-funnel-fill"></i>Filtros</button>
                {/*<button type="button" onClick={async()=> await gerarRelatorio()} className="btn btn-danger btn-cli" data-bs-dismiss="modal"><i className='bi bi-printer-fill'></i>Imprimir</button>
                {/*<button type="button" className="btn btn-danger btn-cli"  data-bs-toggle="modal" data-bs-target="#staticBackdrop"><i className="bi bi-printer-fill"></i> Relatório</button>
                <SalesReport/>*/}
              </div>
        </div> 
        
        <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-sm">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="staticBackdropLabel">Relátorio de Vendas</h1>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <div className="contnt-modal">
                   <h5>Tipo do relatório </h5> 
                  <div className="form-check">
                    <input className="form-check-input" value={0}  type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                      Sintético
                    </label>
                  </div>
                 

                  <div className="form-check">
                    <input className="form-check-input" value={2}  type="radio" name="flexRadioDefault" id="flexRadioDefault2"/>
                    <label className="form-check-label" htmlFor="flexRadioDefault2">
                      Finalizadora
                    </label>
                  </div>

                    
                    <hr />

                   <div className="row">
                     <h5>Período  </h5>   
                       <div className="datas col-8 offset-2">
                         <input type="date" className='form-control'/>
                         <br />
                         <input type="date" className='form-control'/>
                         </div>
                          
                     </div>

                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                              
                  { sResult === 1?
                  <div>                  
                  <button type="button" onClick={ async()=> await gerarRelatorio()} className="btn btn-primary btn-cli" data-bs-dismiss="modal">Imprimir</button>
                  </div>
                  :null} 
                  

                </div>
              </div>
            </div>
          </div>


          <div className="modal fade" id="moda-filtro" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="staticBackdropLabel">Filtros</h1>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <div className="contnt-modal">
                    <div className="row">
                      
                    <div >
                        <select className='form-control' name="status" id="status" value={tpRelatorio} onChange={(e)=> setTipoRelatorio(e.target.value)}>
                           {selectRelatorio.map((tipo) => (<option key={tipo.value} value={tipo.value}> {tipo.descricao} </option>))}
                            
                        </select>
                    </div>                       
                    </div>      
                    <hr />                      

                   <div className="row">
                    <div>
                     <h5>Tipo  </h5>   
                     <div>
                     <div className="form-control d-inline me-3">
                        <select name="status" id="status" onChange={(e)=> setTipo(e.target.value)}>
                            <option value="TODOS">Todos</option>
                            <option value="RECIBO">Recibo</option>
                            <option value="NFCE">NFCe</option>
                        </select>
                    </div>
                    
                   </div>
                   </div>

                   <div>

                   <h5>Caixa  </h5>   
                     <div>
                     <div className="form-control d-inline me-3">
                     <select name="status" onChange={(e) => setCaixa(e.target.value)} id="status">
                        <option value="TODOS">Todos</option>
                          {caixasDisponiveis.map((caixa) => (
                          <option key={caixa.CAIXA} value={caixa.CAIXA}>{caixa.CAIXA}</option>
                            ))}
                        </select>
                     </div>
                    
                     </div>

                     </div>
                          
                     </div>
                     <br />

                     <div className="row">
                    
                    </div>


                    <div className="row">
                     <h5>Período</h5>   
                     <div>
                     <div className='d-flex col-12'>           
                        <input type="date" onChange={(e)=> setDataInicial(e.target.value+"T10:10:00.000Z")} className="form-control" />
                         
                        <input type="date" onChange={(e)=> setDataFinal(e.target.value+"T10:10:00.000Z")} className="form-control" />
                    </div>
                     </div>
                     </div>

                  </div>
                </div>
                <div className="modal-footer">
                  <div>                  
                  { tpRelatorio===0?
                  <button type="button" onClick={filtrarPedidos} className="btn btn-secondary btn-cli" data-bs-dismiss="modal">Ativar</button>
                  :null}
                  
                  <button type="button" onClick={async()=> await gerarRelatorio()} className="btn btn-success btn-cli" data-bs-dismiss="modal">Imprimir</button>
                  </div>

                  

                </div>
              </div>
            </div>
          </div>
        
     

       { load === true?<Loading/>:null}   
       { sResult === 1?
         <div className="row" id='tabela-venda'>
        <table className="table table-hover table-striped">
            <thead>
            <tr>
                <th scope="col">PEDIDO</th>
                <th scope="col">CAIXA</th>
                <th scope="col">CLIENTE</th>
                <th scope="col">Nº NOTA</th>
                <th scope="col">DATA</th>
                <th scope="col">CANCELADO</th>
                <th scope="col">VALOR</th>
                <th scope="col">TIPO</th>
                <th scope="col">AÇÃO</th>
                </tr>
            </thead>
            <tbody className="table-group-divider">
            {  
            pedidos.map((pedido) => {
                return <tr key={pedido.pedido}>
                       <td>{("0000" + pedido.pedido).slice(-6)}</td>
                       <td>{pedido.caixa}</td>
                       <td>{pedido.razao}</td>
                       <td>{pedido.numccf}</td>
                       <td>{pedido.data}</td>
                       <td>{pedido.cancelado}</td>
                       <td> R$ {Number(pedido.valor).toFixed(2)}</td>
                       
                       
                       
                       {pedido.status === "100" ?<td><img src={logoNfce} alt="nfce" width="25x"/></td>  :<td><img src={logoRecibo} alt="recibo" width="40px" /></td> }
                       

                       <td>
                         <div className="dropdown">
                            <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                              Opções
                            </button>
                            <ul className="dropdown-menu">
                            {pedido.status === "100"?
                              <>
                              <li><button className="dropdown-item" onClick={(e) => fetchBase64Data(e.target.value)} value={pedido.chave}><i className="bi bi-printer-fill"></i>Imprimir Nota</button></li>
                              <li><button className="dropdown-item" onClick={(e) => cencelaNFE(e.target.value)} value={pedido.chave}><i className="bi bi-x-circle-fill"></i>Cancelar Nota</button></li>
                              </>:
                              <><li><button onClick={(e) => enviarNFce(e.target.value,65)} value={pedido.pedido} className="dropdown-item"><i className="bi bi-send-fill"></i>Emitir NFCe</button></li>
                                {pedido.responsavel === "0"?null:<li><button onClick={(e) => enviarNFce(e.target.value,55)} value={pedido.pedido} className="dropdown-item"><i className="bi bi-send-fill"></i>Emitir NFe</button></li>}
                                </>}
                              
                                {/* <li><a href={'/app/itenspedido/'+pedido.cod_venda} className="dropdown-item"><i className="bi bi-search"></i>Visualizar</a></li> */}

                            </ul>
                         </div>
                      </td>
                       {/*
                       <td><Link to={'/app/itenspedido/'+pedido.cod_orcamento}><i className="bi bi-search"></i></Link></td>
                       */}

                       </tr>
              } )}  
            </tbody>
          </table>  
          </div>        
          :<BuscaVazaia/>} 

            <div className="row mt-5">
            <SRI_pagination totalPage={totalPaginas} 
                            page={nPag} 
                            limit={10} 
                            siblings={1}
                            onPageChange={handlePageChange}/>
            </div>
        

         {
           confirmaEmissao ? 
           <SweetAlert
              success                         
              confirmBtnText="OK"
              confirmBtnBsStyle="success"
              title="Parabéns"
              onConfirm={() => setConfirmaEmissao(false)}                            
            >
              Nota Emitida com sucesso!
            </SweetAlert>:null
           
          }

          {
           erroEmissao ? 
           <SweetAlert
              danger
              confirmBtnText="OK"
              confirmBtnBsStyle="danger"
              title="Erro"
              onConfirm={() => setErroEmissao(false)}                            
            >
              {msgErro}
            </SweetAlert>:null
           
          }
 
        

      </div>      
    </div>
}


export default ListaPedidos;